<template>
  <b-form @submit.stop.prevent>
    <h3>{{ isSpecialAgent ? $t('booking.general-information') : $t('booking.internal-info-title') }}</h3>
    <b-form-group id="input-group-1" :label="isFCLAgent ? `${$t('booking.care-agent')}:` : (isSpecialAgent ? `${$t('booking.the-flying-carpet-agent')}:` : `${$t('booking.clerk-name')}:`)" label-for="input-1">
      <b-form-input
        id="input-1"
        v-model="clerkName"
        type="text"
        disabled
        v-if="isFCLAgent"
      ></b-form-input>
      <b-form-input
        id="input-1"
        value="siryaa - 0523345212"
        type="text"
        disabled
        v-else-if="isSpecialAgent"
      ></b-form-input>
      <v-select
        v-model="clerkName"
        :options="clerkNameList"
        dir="rtl"
        :clearable="false"
        :searchable="true"
        :disabled="readOnlyState || isSpecialAgent"
        v-else
      >
      </v-select>
    </b-form-group>

    <b-form-group id="input-group-2" :label="isSpecialAgent ? `${$t('booking.company-name')}:` : `${$t('booking.agent-list')}:`" label-for="input-2">
      <v-select
        v-model="agent"
        :reduce="agent => agent.value"
        :options="agentList"
        dir="rtl"
        :clearable="false"
        :searchable="true"
        :disabled="!isFCLAgent || isExternal || readOnlyState"
      >
      </v-select>
    </b-form-group>

    <b-form-group id="input-group-3" :label="isFCLAgent ? `${$t('booking.discount-amount')}:` : (isSpecialAgent ? `${$t('booking.total-payment')}:` : `${$t('search-result.net-price')}:`)" label-for="input-3">
      <b-form-input
        dir="ltr"
        id="input-3"
        v-model="salesAmount"
        type="text"
        class="agent-amount text-right"
        :state="!!Number(salesAmount.slice(1))"
        @change="updateAmount"
        :readonly="readOnlyState"
      ></b-form-input>
    </b-form-group>

    <div v-if="!isSpecialAgent">
      <b-form-group id="input-group-4" :label="`${$t('booking.partial-amount')}:`" label-for="input-4" v-if="isFCLAgent">
        <b-form-input
          dir="ltr"
          id="input-4"
          v-model="partialAmount"
          type="text"
          class="agent-amount text-right"
          @change="updatePartialAmount"
          :readonly="readOnlyState"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-5" :label="`${$t('booking.remark-by-agent')}:`" label-for="input-5">
        <b-form-textarea
          id="input-5"
          v-model="agentRemark"
          rows="3"
          max-rows="6"
          :placeholder="$t('booking.remark-by-agent')"
          @change="changeRemark"
          :disabled="isExternal"
          :readonly="readOnlyState"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group id="input-group-6" :label="`${$t('booking.remark-to-hotel')}:`" label-for="input-6" v-if="!bypassPayment || isFCLAgent">
        <b-form-textarea
          id="input-6"
          v-model="hotelRemark"
          rows="3"
          max-rows="6"
          :placeholder="$t('booking.remark-to-hotel')"
          @change="changeRemarkToHotel"
          :disabled="isExternal"
          :readonly="readOnlyState"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group id="input-group-7" v-if="isFCLAgent">
        <b-form-checkbox v-model="tourOpIsIntatl19ByAgent " :value="true" :disabled="isExternal">{{ $t('booking.tourOpIsIntatl19') }}</b-form-checkbox>
      </b-form-group>
      <b-form-group id="input-group-8">
        <b-form-checkbox v-model="bypassPayment" :value="true" :disabled="isExternal">{{ $t('booking.bypass-payment') }}</b-form-checkbox>
      </b-form-group>
      <b-form-group id="input-group-9" :label="`${$t('booking.pnr-update')}:`" label-for="input-8" v-if="isFCLAgent && !retryState">
        <b-form-input
          dir="ltr"
          id="input-8"
          v-model="pnrToUpdate"
          type="text"
          class="agent-amount text-right"
        ></b-form-input>
      </b-form-group>
    </div>

  </b-form>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea } from 'bootstrap-vue';
import { fromBase64 } from '@/utils/base64Util';

export default {
  name: 'InternalAgentInformation',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
  },
  props: {
    currency: {
      type: String,
      default: 'US',
    },
    fullPrice: {
      type: Number,
      default: 0,
    },
    netPrice: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      agent: '',
      clerkName: '',
      agentRemark: '',
      hotelRemark: '',
      initialBalance: 0,
      initialPartial: 0,
      bypassPayment: false,
      isPartial: false,
      pnrToUpdate: '',
      tourOpIsIntatl19ByAgent: false,
    };
  },
  computed: {
    ...mapGetters({
      allAgentList: 'GET_ALL_AGENT_LIST',
      agentContent: 'GET_ODYSSEY_AGENT_CONTENT',
      loginClerkName: 'GET_ODYSSEY_AGENT_CLERK_NAME',
      odyAgentCode: 'GET_LOGIN_ODYSSEY_AGENT_CODE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      externalBookingInfo: 'GET_EXTERNAL_BOOKING_INFO',
    }),
    isExternal() {
      return this.externalBookingInfo !== null;
    },
    agentList() {
      const list = this.allAgentList;
      if (list.length === 0) return [];
      return list.map((elm, inx) => ({ id: inx, value: elm.agt_Code, label: `${elm.agt_Code} - ${elm.agt_Name}` }));
    },
    clerkNameList() {
      // return [this.$t('search-tab.choose-count-select'), 'rinay', 'siryaa', 'kinerets', 'innaz'];
      return [this.$t('search-tab.choose-count-select'), 'siryaa', 'margaritav', 'annaa', 'vikas', 'lenae'];
    },
    salesAmount: {
      get() {
        return `₪${this.initialBalance}`;
      },
      set(newValue) {
        this.initialBalance = Number(newValue.split(/ /)[0].replace(/[^\d^.]/g, ''));
      },
    },
    partialAmount: {
      get() {
        return `₪${this.initialPartial}`;
      },
      set(newValue) {
        this.initialPartial = Number(newValue.split(/ /)[0].replace(/[^\d^.]/g, ''));
      },
    },
    retryState() {
      const retry = JSON.parse(window.localStorage.getItem('retryState'));
      return retry != null;
    },
    readOnlyState() {
      const retry = JSON.parse(window.localStorage.getItem('retryState'));
      return retry?.readOnly || false;
    },
    isSpecialAgent() {
      if (!this.agentContent.agentSiteSpecificProps) return false;
      return this.agentContent.agentSiteSpecificProps.visitorLikeExtAgents.includes(this.agent);
    },
  },
  watch: {
    bypassPayment() {
      this.update();
      this.$store.commit('SET_ODYSSEY_BYPASS_PAYMENT', this.bypassPayment);
    },
    tourOpIsIntatl19ByAgent() {
      this.update();
    },
    clerkName() {
      this.update();
    },
    salesAmount() {
      this.update();
    },
    agent() {
      this.update();
    },
    remarkByAgent() {
      this.update();
    },
    remarkToHotel() {
      this.update();
    },
    partialAmount() {
      this.update();
    },
    pnrToUpdate() {
      this.update();
    },
    isExternal() {
      if (this.isExternal) {
        this.agent = this.externalBookingInfo.bookingData.odyAgentCode;
        this.bypassPayment = this.externalBookingInfo.bookingData.bookTransaction.bypassPaymentByAgent;
      }
    },
  },
  async created() {
    await this.$store.dispatch('FETCH_ALL_AGENT_LIST');
  },
  mounted() {
    this.agent = this.odyAgentCode;
    // eslint-disable-next-line no-nested-ternary
    this.salesAmount = this.isExternal ? `₪${this.fullPrice}` : this.isFCLAgent ? `₪${this.fullPrice}` : `₪${this.netPrice}`;
    this.partialAmount = this.salesAmount;
    this.clerkName = this.isFCLAgent || this.isSpecialAgent ? this.loginClerkName : this.clerkNameList[0];
    this.update();
    if (this.isExternal) {
      this.agent = this.externalBookingInfo.bookingData.odyAgentCode;
      this.bypassPayment = this.externalBookingInfo.bookingData.bookTransaction.bypassPaymentByAgent;
    }

    const retry = window.localStorage.getItem('retryState');
    const dataBook = JSON.parse(fromBase64(window.localStorage.getItem('BookingDetailInfo')));
    const BookingDetailInfo = dataBook.data;
    const { sendingStage } = dataBook;
    if (retry !== 'null' && sendingStage !== 'init') {
      this.clerkName = BookingDetailInfo.ClerkNameByAgent;
      this.agent = BookingDetailInfo.OdyAgentCodeByAgent;
      this.salesAmount = `₪${BookingDetailInfo.grandTotalAfterDiscount}`;
      this.partialAmount = `₪${BookingDetailInfo.partialPayment}`;
      this.agentRemark = BookingDetailInfo.remarkByAgent;
      this.hotelRemark = BookingDetailInfo.remarkToHotel;
      this.tourOpIsIntatl19ByAgent = BookingDetailInfo.tourOpIsIntatl19ByAgent;
    }
  },
  methods: {
    update() {
      if (this.isExternal) return;

      this.$emit('updateInternalAgentInfo', {
        clerkName: (this.clerkNameList[0] === this.clerkName) ? '' : this.clerkName,
        OdyAgentCodeByAgent: this.agent,
        SalesAmount: +(this.salesAmount.slice(1)),
        partialPayment: +(this.partialAmount.slice(1)),
        BypassPaymentByAgent: this.bypassPayment,
        remarkByAgent: this.agentRemark,
        remarkToHotel: (this.bypassPayment && !this.isFCLAgent) ? '' : this.hotelRemark,
        pnrToUpdate: this.pnrToUpdate,
        tourOpIsIntatl19ByAgent: this.tourOpIsIntatl19ByAgent,
      });
    },
    changeRemark() {
      this.update();
    },
    changeRemarkToHotel() {
      this.update();
    },
    updateAmount() {
      const salesAmount = +(this.salesAmount.slice(1));
      // const compareAmount = this.isExternal ? this.fullPrice : this.isFCLAgent ? this.fullPrice : this.netPrice;
      const compareAmount = this.netPrice;
      if (salesAmount < compareAmount) {
        this.salesAmount = `₪${compareAmount}`;
        const message = this.$t('booking.toast-incorrect-amount');
        const id = 'error-toast';
        this.$bvToast.toast([message], {
          id,
          title: 'ERROR',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          noCloseButton: true,
          bodyClass: this.lang === 'he' ? 'rtl' : 'ltr',
        });
      }
      this.partialAmount = this.salesAmount;
    },
    updatePartialAmount() {
      const salesAmount = +(this.salesAmount.slice(1));
      const partialAmount = +(this.partialAmount.slice(1));
      if (partialAmount > salesAmount) {
        this.partialAmount = `₪${salesAmount}`;
      }
    },
  },
};
</script>
<style scoped>
  .agent-amount{
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 12px !important;
  }
</style>
